<template>
    <div>
    <div class="row">
        <div class="col">
            <h5 class="h6 my-3">{{$t('motor.management')}}</h5>
        </div>

        <!-- Preferovaná po otevření stránky bude tabulka s názvem Trendy.
        Pod ní uděláme přehledový graf. V tomto grafu budeme ukazovat hodnoty z Aktivit: Měření, Údržba, Mazání.
        Aktivita Měření pak dostane další dělení na Proudy, Vibrace, teploty.
        Každé měření teploty, zaznamená tři body do grafu /tři měřící místa).
        Při zobrazení delšího časového úseku pak dané body propojíme křivkou a uvidím tedy 3 křivky.
        Hodila by se možnost zoomu na konkrétní jednotlivá měření.
Pokud najedu myší na jednotlivé záznamy měření, vyskočí šedé okno s naměřenými hodnotami. Pod grafem bude časová osa.
-->

    </div>
    <div class="row">
        <div class="col">
            <div  id="chartcontainer">
                <!-- Nav tabs -->
                <ul class="nav nav-tabs">
                    <li class="nav-item">
                        <a class="nav-link active" data-toggle="tab" href="#home1">{{ $t('motor.management.timeline') }}</a>
                    </li>

                    <li class="nav-item">
                        <a class="nav-link" data-toggle="tab" href="#menu2" @click.prevent="grafVibrace" id="a_vib">{{$t("motor.management.vibrace")}}</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" data-toggle="tab" href="#menu3" @click.prevent="grafTeploty" id="a_temp">{{$t("motor.management.teploty")}}</a>
                    </li>
                </ul>

                <!-- Tab panes -->
                <div class="tab-content">
                    <div class="tab-pane  active osaa" id="home1">
                        <Calendar :motor="motor" :mikro="false"></Calendar>
<!--
                        <ul class="timeline">
                            <li class="timeline-item" v-for="a in aktivity" :key="a.id" @click.prevent="showActivity(a)">

                                <div class="timeline-badge "><img :src="ikonaAktivity(a)"></div>
                                <div class="timeline-panel">
                                    <div class="timeline-heading">
                                        <h4 class="timeline-title">{{ activityTypeName(a)}}</h4>
                                        <div class="timeline-panel-controls">
                                            <div class="controls">

                                            </div>
                                            <div class="timestamp">
                                                <small class="text-muted">{{ df(a.Time, 'LL') }}</small>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="timeline-body"><p>{{ a.Note1 || a.Note2 }}</p></div>
                                </div>

                            </li>
                        </ul>
-->
                    </div>
                    <div class="tab-pane  fade" id="menu2">

                        <div class="form-datums row mt-2">
                            <div class="col-md-1 pr-0"><label>Datum od</label></div>
                            <div class="col-md-2"><datepicker format="dd.MM.yyyy" :value="d1" @selected="changeD1"></datepicker></div>

                            <div class="col-md-1 pr-0"><label>Datum do</label></div>
                            <div class="col-md-2"><datepicker format="dd.MM.yyyy" :value="d2" @selected="changeD2"></datepicker></div>

                        </div>
                        <GChart
                                type="LineChart"
                                :data="chartDataVib"
                                :options="chartOptions" :key="chartKey"
                                v-if="chartDataVib.length>1"
                        />
                        <p  class="alert alert-warning mt-3 text-center font-weight-bold" v-else> {{$t('motor.chart.no-data')}}</p>
                    </div>
                    <div class="tab-pane  fade" id="menu3">
                        <div class="form-datums row mt-2">
                            <div class="col-md-1 pr-0"><label>Datum od</label></div>
                            <div class="col-md-2"><datepicker format="dd.MM.yyyy" :value="d1" @selected="changeD1"></datepicker></div>

                            <div class="col-md-1 pr-0"><label>Datum do</label></div>
                            <div class="col-md-2"><datepicker format="dd.MM.yyyy" :value="d2" @selected="changeD2"></datepicker></div>

                        </div>
                        <GChart
                                type="LineChart"
                                :data="chartDataT"
                                :options="chartOptions" :key="chartKey2"
                                v-if="chartDataT.length>1"
                        />

                        <p  class="alert alert-warning mt-3 text-center font-weight-bold" v-else> {{$t('motor.chart.no-data')}}</p>
                    </div>
                </div>



            </div>
        </div>





    </div>
    </div>
</template>

<script>
    import Calendar from "./Calendar";

    //import AktivityModal from "./AktivityModal";
    import Vue from 'vue'
    import VueGoogleCharts from 'vue-google-charts'
    Vue.use(VueGoogleCharts)

    import Datepicker from 'vuejs-datepicker';

 const moment = require('moment');
    require('moment/locale/cs');
    moment.locale('cs');
    export default {
        name: "Chart",
           components: {Calendar,  Datepicker},
        watch:{

            d1(){
                console.log('d1', this.d1)
            }
        },

        data(){
            return {
                chartKey2:2,
                chartKey:2,
                d1: moment().subtract(1, 'months').toDate(),
                d2: moment().add(1, 'months').toDate(),
                akt: [],
                dg: [],
                motor: [],
                chartDataI: [],
                chartDataT: [],
                chartDataVib: [],
                chartOptions1: {interpolateNulls: true,height:600, width:900 ,vAxis: {
                        showTextEvery: 2,
                        left: 0,


                        textStyle: {
                            color: '#1a237e',
                            fontSize: 24,
                            bold: true
                        }
                }

                    },
                chartOptions: {
                    height: 400,
                    width: 500,
                    isStacked: true,
                    interpolateNulls: true,
                    hAxis: {
                        format: 'd.M.yy',
                        title: 'Date',
                        showTextEvery: 5,
                        textStyle: {fontSize: 11},
                        viewWindowMode:'explicit',
                        minValue: moment().subtract(1, 'months').toDate(),
                        viewWindow: {
                            min: moment().subtract(1, 'months').toDate(),
                            max: moment().add(1, 'months').toDate()
                        },
                    },
                    vAxis: {
                        showTextEvery: 2,
                        left: 0,
                        gridlines: {
                            count: 5
                        },
                        title: "Y",
                        viewWindow: {
                            min: 0
                       //     max: 200
                        },

                    },
                    curveType: 'function',
                    pointSize: 3

                },
                uzgraf:true
            }
        },
        methods:{

            grafVibrace(){
                this.chartOptions.vAxis.title = this.$t('motor.management.vibrace')+" [mm/ss]"
                //this.chartOptions.vAxis.viewWindow.max = 20
                setTimeout(()=>{
                    this.chartKey++
                }, 500)
            },

            grafTeploty(){
                this.chartOptions.vAxis.title = this.$t('motor.management.teploty')+" [°C]"
                //this.chartOptions.vAxis.viewWindow.max = 200

                setTimeout(()=>{
                    this.chartKey2++
                }, 500)
            },

            changeD1(a){
                //console.log('d1', a, this.d1)
                this.d1 = a
                this.chartOptions.hAxis.viewWindow.min = a




            },
            changeD2(a){
                //console.log('d2', this.d2)
                this.d2=a
                this.chartOptions.hAxis.viewWindow.max = a

            },

            df: function (time, format) {
              moment.locale(this.$i18n.locale);
                if (!time) return '';
                var a = moment(time);
                return a.format(format ? format : "LLL")
            },
            prepareData(){

                this.chartDataI=[['Date, time', 'Current L1', 'Current L2', 'Current L3']];
                var vib = [['Date, time', 'Vibration axis A', 'Vibration axis B' ]];
                this.chartDataT=[['Date, time', 'Temperature T1', 'Temperature T2', 'Temperature T3']];
                var row;
                for(let i in this.motor.activities){
                    const a = this.motor.activities[i]
                    if (parseInt(a.ActivityType) == 5) {
                        let d = new Date(a.Time)
                        row = [d,
                            parseFloat(a.VibrationAxisA) > 0 ? parseFloat(a.VibrationAxisA) : null,
                            parseFloat(a.VibrationAxisB) > 0 ? parseFloat(a.VibrationAxisB) : null,
                            //parseFloat(a.VibrationAxisC) > 0 ? parseFloat(a.VibrationAxisC) :  null,
                            /*parseFloat(a.VibrationAxisD),
                            parseFloat(a.VibrationAxisE),
                            parseFloat(a.VibrationAxisF)*/]

                      if (parseFloat(a.VibrationAxisA) > 0)       vib.push(row)

           //             this.chartDataI.push([d,  parseFloat(a.CurrentL1),  parseFloat(a.CurrentL2),  parseFloat(a.CurrentL3)])
                        if (parseFloat(a.TemperatureT1)!=0 ) this.chartDataT.push([d,
                            parseFloat(a.TemperatureT1)>0 ? parseFloat(a.TemperatureT1) : null,
                            parseFloat(a.TemperatureT2)>0 ? parseFloat(a.TemperatureT2) : null,
                            parseFloat(a.TemperatureT3)>0 ? parseFloat(a.TemperatureT3) : null])
                    }
                }
                //console.log("VIB", vib)

                this.chartDataVib = vib;

                //console.log('data', this.chartDataT)
            },
            resizeChart(){
              const el = document.getElementById('chartcontainer')
              if (el){
                var w= el.clientWidth
                this.chartOptions.width = w -40
              }
            }
        },
        mounted(){
            this.motor = this.$parent.motor
            this.$parent.$on('motorUpdated', () => {
                this.motor = this.$parent.motor
                this.prepareData()
                this.$emit('motorUpdated2', this.motor)


            })
            if (this.motor && this.motor.id>0)  this.prepareData()


            window.addEventListener('resize', this.resizeChart);
            this.resizeChart()

                if (this.$route.hash == '#temp') {
                    document.getElementById("a_temp").click();
                }
                if (this.$route.hash == '#vib') {
                    document.getElementById("a_vib").click();

                }


        }
    }
</script>

<style scoped lang="scss">
.tab-pane{

}
.osaa{


    .timeline {
        list-style: none;
        padding: 20px 0 20px;
        position: relative;
        max-width:600px;

        &:before {
            background-color: #bbb;
            bottom: 0;
            content: " ";
            left: 50px;
            margin-left: -1.5px;
            position: absolute;
            top: 0;
            width: 3px;
        }

        > li {
            cursor: pointer;
            margin-bottom: 20px;
            position: relative;

            &:before,
            &:after {
                content: " ";
                display: table;
            }

            &:after {
                clear: both;
            }

            > .timeline-panel {
                border-radius: 2px;
                border: 1px solid #d4d4d4;
                box-shadow: 0 1px 2px rgba(100, 100, 100, 0.2);
                margin-left: 100px;
                padding: 20px;
                position: relative;
                background: white;

                .timeline-heading {
                    .timeline-panel-controls {
                        position: absolute;
                        right: 8px;
                        top: 5px;

                        .timestamp {
                            display: inline-block;
                        }

                        .controls {
                            display: inline-block;
                            padding-right: 5px;
                            border-right: 1px solid #aaa;

                            a {
                                color: #999;
                                font-size: 11px;
                                padding: 0 5px;

                                &:hover {
                                    color: #333;
                                    text-decoration: none;
                                    cursor: pointer;
                                }
                            }
                        }

                        .controls + .timestamp {
                            padding-left: 5px;
                        }
                    }
                }
            }

            .timeline-badge {
                background-color: #fff;
                border: 1px solid silver;
                padding: 3px;
                border-radius: 50%;
                color: #fff;
                font-size: 1.4em;
                height: 50px;
                left: 50px;
                line-height: 52px;
                margin-left: -25px;
                position: absolute;
                text-align: center;
                top: 16px;
                width: 50px;
                z-index: 100;
                img{
                    margin:5px auto;
                    display: block;
                }
            }

            .timeline-badge + .timeline-panel {
                &:before {
                    border-bottom: 15px solid transparent;
                    border-left: 0 solid #ccc;
                    border-right: 15px solid #ccc;
                    border-top: 15px solid transparent;
                    content: " ";
                    display: inline-block;
                    position: absolute;
                    left: -15px;
                    right: auto;
                    top: 26px;
                }

                &:after {
                    border-bottom: 14px solid transparent;
                    border-left: 0 solid #fff;
                    border-right: 14px solid #fff;
                    border-top: 14px solid transparent;
                    content: " ";
                    display: inline-block;
                    position: absolute;
                    left: -14px;
                    right: auto;
                    top: 27px;
                }
            }
        }
    }

    .timeline-badge {
        &.primary {
            background-color: #2e6da4 !important;
        }

        &.success {
            background-color: #3f903f !important;
        }

        &.warning {
            background-color: #f0ad4e !important;
        }

        &.danger {
            background-color: #d9534f !important;
        }

        &.info {
            background-color: #5bc0de !important;
        }
    }

    .timeline-title {
        margin-top: 0;
        color: inherit;
    }

    .timeline-body {
        > p,
        > ul {
            margin-bottom: 0;
        }

        > p + p {
            margin-top: 5px;
        }
    }

    .copy {
        position: absolute;
        top: 5px;
        right: 5px;
        color: #aaa;
        font-size: 11px;
        > * { color: #444; }
    }
}

    .nav-link.active{
        background:transparent;
        border-bottom:1px solid  #f0f0f0;
    }

    .form-datums{
        height:40px;

        label{
            display: block;
            text-align: right;
            line-height: 35px;
        }

    }
</style>
